import { getLocale, messages } from '@algorh/shared'

import { createI18n } from 'vue-i18n'

export default createI18n({
  legacy: false, // Required to use the Composition API
  locale: getLocale(),
  fallbackLocale: 'fr',
  messages,
  globalInjection: true,
})
