import { computed } from 'vue'

import { useQuery } from '@tanstack/vue-query'

import { AbsencesApiService } from '@/api/absences'
import { AuthApiService } from '@/api/auth.service'
import { CalendarApiService } from '@/api/calendar'
import { CommonApiService } from '@/api/common.service'
import { EmployeesApiService } from '@/api/employees'
import { SchedulingApiService } from '@/api/scheduling'
import { SettingsApiService } from '@/api/settings'

import { useAppInit } from './useAppInit'
import { StockExchangesApiService } from '@/api/stock-exchanges'

const staleTime = 24 * 60 * 60 * 1000 // 24H

export function useConfigs() {
  const { permissions, isAuthenticated } = useAppInit()

  // Computed
  const canFetchSettingsConfig = computed(() => [
    permissions.value?.access_hyper_periods,
    permissions.value?.manage_activities,
    permissions.value?.manage_rules_and_ambitions,
    permissions.value?.access_tags,
    permissions.value?.manage_users,
    permissions.value?.access_work_cycles,
    permissions.value?.manage_subdivisions,
    permissions.value?.manage_roles,
  ].includes(true))

  const canFetchStockExchangesConfig = computed(() => calendar?.value?.data?.stock_exchanges ?? false)

  const canFetchCalendarConfig = computed(() => permissions.value?.access_calendar ?? false)

  const canFetchEmployeeConfig = computed(() => permissions.value?.manage_customer_advisers ?? false)

  const absencesEnabled = computed(() => common.value?.data?.absences ?? false)

  const schedulingEnabled = computed(() => common.value?.data?.scheduling ?? false)

  const rgpdEnabled = computed(() => common.value?.data?.rgpd ?? false)

  const notificationsEnabled = computed(() => common.value?.data?.notifications ?? false)

  const leaveBalancesImportEnabled = computed(() => absences.value?.data?.leave_balances_import ?? false)

  const supportEnabled = computed(() => common?.value?.data?.support ?? false)

  const slotDuration = computed(
    () => scheduling.value?.data?.slot_duration || settings.value?.data?.slot_duration,
  )

  const documentationEnabled = computed(() => common.value?.data?.documentation ?? false)

  const hyperPeriodEnabled = computed(() => settings.value?.data?.hyper_period ?? null)

  const noticeEnabled = computed(() => common.value?.data?.notice ?? false)

  const workCycleEnabled = computed(() => common.value?.data?.work_cycle ?? false)

  const autocompleteUserInfos = computed(() => settings.value?.data?.autocomplete_user_infos ?? null)

  const criticalPeriodEnabled = computed(
    () => (absences?.value?.data?.critical_period ?? false) || (settings?.value?.data?.critical_period ?? false),
  )

  const realTimeModeEnabled = computed(
    () => (scheduling.value?.data?.real_time_mode || calendar.value?.data?.real_time_mode) ?? false,
  )

  const predefinedModeEnabled = computed(
    () => (employees.value?.data?.predefined_mode ?? false) || (calendar.value?.data?.predefined_mode ?? false),
  )

  const nonProductiveActivityQuotaEnabled = computed(
    () => settings.value?.data?.non_productive_activity_quota ?? false,
  )

  const levelEnabled = computed(
    () =>
      (employees.value?.data?.skills_with_level
      || calendar.value?.data?.skills_with_level
      || settings.value?.data?.skills_with_level) ?? false,
  )

  const velocityEnabled = computed(
    () =>
      (employees.value?.data?.skills_with_velocity
      || calendar.value?.data?.skills_with_velocity
      || settings.value?.data?.skills_with_velocity) ?? false,
  )

  const isLoadingConfig = computed(() => isLoadingSettings
    || isLoadingAuth
    || isLoadingCalendar
    || isLoadingEmployees
    || isLoadingCommon
    || isLoadingAbsences
    || isLoadingScheduling
    || isLoadingStockExchanges,
  )

  // Composable
  const { data: settings, isLoading: isLoadingSettings } = useQuery({
    enabled: canFetchSettingsConfig,
    queryKey: ['settings', 'config'],
    queryFn: SettingsApiService.getSettingsConfig,
    staleTime,
  })

  const { data: common, isLoading: isLoadingCommon } = useQuery({
    enabled: isAuthenticated,
    queryKey: ['common', 'config'],
    queryFn: CommonApiService.getCommonConfig,
    staleTime,
  })

  const { data: employees, isLoading: isLoadingEmployees } = useQuery({
    enabled: canFetchEmployeeConfig,
    queryKey: ['employees', 'config'],
    queryFn: EmployeesApiService.getConfig,
    staleTime,
  })

  const { data: calendar, isLoading: isLoadingCalendar } = useQuery({
    enabled: canFetchCalendarConfig,
    queryKey: ['calendar', 'config'],
    queryFn: CalendarApiService.getCalendarConfig,
    staleTime,
  })

  const { data: absences, isLoading: isLoadingAbsences } = useQuery({
    enabled: absencesEnabled,
    queryKey: ['absences', 'config'],
    queryFn: AbsencesApiService.getAbsencesConfig,
    staleTime,
  })

  const { data: scheduling, isLoading: isLoadingScheduling } = useQuery({
    enabled: schedulingEnabled,
    queryKey: ['scheduling', 'config'],
    queryFn: SchedulingApiService.getSchedulingConfig,
    staleTime,
  })

  const { data: stockExchanges, isLoading: isLoadingStockExchanges } = useQuery({
    enabled: canFetchStockExchangesConfig,
    queryKey: ['stock-exchanges', 'config'],
    queryFn: StockExchangesApiService.getStockExchangesConfig,
    staleTime,
  })

  const { data: auth, isLoading: isLoadingAuth } = useQuery({
    enabled: true,
    queryKey: ['auth', 'config'],
    queryFn: AuthApiService.fetchAuthConfig,
    staleTime,
  })

  return {
    slotDuration,
    realTimeModeEnabled,
    autocompleteUserInfos,
    velocityEnabled,
    levelEnabled,
    workCycleEnabled,
    predefinedModeEnabled,
    nonProductiveActivityQuotaEnabled,
    supportEnabled,
    hyperPeriodEnabled,
    criticalPeriodEnabled,
    leaveBalancesImportEnabled,
    documentationEnabled,
    noticeEnabled,
    notificationsEnabled,
    schedulingEnabled,
    absencesEnabled,
    rgpdEnabled,
    settings,
    absences,
    scheduling,
    employees,
    common,
    calendar,
    auth,
    isLoadingConfig,
    stockExchanges,
  }
}
