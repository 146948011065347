import { createApp } from 'vue'

import { createPinia } from 'pinia'

import * as Sentry from '@sentry/vue'

import { VueQueryPlugin } from '@tanstack/vue-query'

import '@algorh/ui/src/index.css'

import i18n from '@/plugins/i18n'

import { initLang } from '@/plugins/langjs'

import { router } from '@/router'

import App from './App.vue'

import { version } from '../../../package.json'

import { queryClient } from './queryClient'

import './sass/app.scss'

const app = createApp(App)

const pinia = createPinia()

const vueRouter = router()

app.use(pinia)
app.use(vueRouter)
app.use(i18n)

app.use(VueQueryPlugin, { queryClient })

initLang(app)

if (import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    release: `algorh@${version}`,
    maxValueLength: 1000,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    ignoreErrors: [
      /Non-Error exception captured/,
      /Failed to fetch/,
      /Object captured as promise rejection with keys: message, status/,
    ],
    tracesSampleRate: 0.0,
  })
}

app.mixin({ inheritAttrs: true }).mount('#app')
export { queryClient }
