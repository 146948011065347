<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, watch, ref } from 'vue'

  import { useI18n } from 'vue-i18n'

  import { IconName } from '#/types'

  import { AlertVariant, AlgIcon } from '#/components'

  defineOptions({
    name: 'AlgAlert',
  })

  const props = withDefaults(defineProps<Props>(), {
    variant: 'success',
    autoHide: true,
    closable: true,
  })

  const MAX = 100

  const DELAY = 5 // In seconds

  type Props = {
    id: string | number
    variant?: AlertVariant
    title?: string
    message: string
    autoHide?: boolean
    closable?: boolean
    onClose?: () => void
  }

  const { t } = useI18n()

  const interval = ref<number>(0)

  const timeout = ref(MAX)

  const computedTitle = computed<string>(() => {
    if (props.title) {
      return props.title
    }

    switch (props.variant) {
    case 'info':
      return t('common.Information')
    case 'success':
      return t('common.Success')
    case 'warning':
      return t('common.Warning')
    case 'danger':
      return t('common.Error')
    default:
      return ''
    }
  })

  const icon = computed<IconName>(() => {
    switch (props.variant) {
    case 'info':
      return 'info'
    case 'success':
      return 'task-alt'
    case 'warning':
      return 'warning'
    case 'danger':
      return 'cancel'
    default:
      return 'block'
    }
  })

  const backgroundColor = computed(() => {
    switch (props.variant) {
    case 'info':
      return 'var(--alg-color-light-blue)'
    case 'success':
      return 'var(--alg-color-light-green)'
    case 'warning':
      return 'var(--alg-color-light-orange)'
    case 'danger':
      return 'var(--alg-color-light-red)'
    default:
      return 'var(--alg-color-surface-primary)'
    }
  })

  const color = computed(() => {
    switch (props.variant) {
    case 'info':
      return 'var(--alg-color-state-info)'
    case 'success':
      return 'var(--alg-color-state-success)'
    case 'warning':
      return 'var(--alg-color-state-warning)'
    case 'danger':
      return 'var(--alg-color-state-danger)'
    default:
      return 'var(--alg-color-text-primary)'
    }
  })

  function decrementTimeout(): void {
    timeout.value -= 1

    if (timeout.value === 0) {
      handleClose()
    }
  }

  function handleClose(): void {
    window.clearInterval(interval.value)
    if (props.onClose) {
      props.onClose()
    }
  }

  function init(): void {
    if (props.autoHide) {
      timeout.value = MAX
      interval.value = window.setInterval(decrementTimeout, (DELAY * 1000) / MAX)
    }
  }

  watch(
    () => props.autoHide,
    () => {
      init()
    },
  )

  onMounted(() => {
    init()
  })

  onBeforeUnmount(() => {
    window.clearInterval(interval.value)
  })
</script>

<template>
  <div
    class="alert"
    :style="{
      '--color-value': color
    }"
  >
    <div class="inner">
      <div
        class="icon"
        :style="{ backgroundColor }"
      >
        <AlgIcon
          :name="icon"
          :color="color"
          size="m"
        />
      </div>
      <div class="content">
        <div
          class="title"
          :style="{ color }"
        >
          {{ computedTitle }}
        </div>
        <p class="message">
          {{ props.message }}
        </p>
      </div>
      <button
        v-if="props.closable"
        type="button"
        @click.stop="handleClose"
      >
        <AlgIcon
          name="close"
          size="s"
          color="var(--alg-color-icon-secondary)"
        />
      </button>
    </div>
    <progress
      class="progress-bar"
      max="100"
      :value="timeout"
    />
  </div>
</template>

<style lang="scss" scoped>
  .alert {
    z-index: 140;
    display: flex;
    overflow: hidden;
    width: 372px;
    flex-direction: column;
    border-radius: var(--alg-effect-radius-s);
    background-color: var(--alg-color-surface-primary);
    box-shadow: var(--alg-effect-shadow-m);

    .inner {
      position: relative;
      display: flex;
      padding: var(--alg-spacing-s) var(--alg-spacing-s) 0.375rem var(--alg-spacing-s);
      gap: var(--alg-spacing-s);

      .icon {
        display: flex;
        width: var(--alg-spacing-xxl);
        height: var(--alg-spacing-xxl);
        align-items: center;
        justify-content: center;
        border-radius: var(--alg-effect-radius-s);
        aspect-ratio: 1;
      }

      .content {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
        gap: var(--alg-spacing-xs);

        .title {
          font-size: var(--alg-font-size-xs);
          font-weight: var(--alg-font-weight-bold);
          text-transform: uppercase;
        }

        .message {
          margin: 0;
          font-size: var(--alg-font-size-m);
          font-weight: var(--alg-font-weight-regular);
          text-transform: lowercase;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      button {
        position: absolute;
        top: var(--alg-spacing-s);
        right: var(--alg-spacing-s);
        padding: var(--alg-spacing-xs);
        padding: 0;
        border: none;
        appearance: none;
        background-color: var(--alg-color-transparent);
        color: var(--alg-color-icon-unselected);
        cursor: pointer;
        transition: color 150ms ease-in-out;

        &:hover {
          color: var(--alg-color-icon-secondary);
        }
      }
    }

    .progress-bar {
      width: 100%;
      height: var(--alg-spacing-xs);
      border: none;
      appearance: none;
      background-color: var(--alg-color-surface-border);
    }

    ::-webkit-progress-bar {
      background-color: var(--alg-color-surface-border);
    }

    ::-webkit-progress-value {
      background-color: var(--color-value);
    }

    ::-moz-progress-bar {
      background-color: var(--color-value);
    }
  }
</style>
