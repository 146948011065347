<script lang="ts" setup>
  import { ref } from 'vue'

  import { useI18n } from 'vue-i18n'

  import { useRouter } from 'vue-router'

  import { AlgButton, AlgTextInput } from '@algorh/ui'

  import { AuthApiService } from '@/api/auth.service'

  import { useAlertsStore, useAppInit, useNotifications } from '@/composables'

  import { queryClient } from '@/queryClient'

  // Composables
  const { t } = useI18n()

  const router = useRouter()

  const { getNotifications } = useNotifications()

  const alertsStore = useAlertsStore()

  // Data
  const { me } = useAppInit()

  const { setAlert } = alertsStore

  // Refs
  const isLoggingOut = ref(false)

  /*
    const languageOptions = ref([
      { value: 'fr', label: 'Français' },
      { value: 'en', label: 'English' }
    ])
    */

  // Methods
  /*
    function handleChangeLanguage(value: string) {
      window.localStorage.setItem('lang', value)
      window.location.reload()
    }
  */

  async function handleLogout() {
    isLoggingOut.value = true

    try {
      await AuthApiService.logout()

      await queryClient.setQueryData(['me'], null)

      await router.replace({ name: 'login' })

      setAlert({
        variant: 'success',
        message: t('account.Logout successful'),
      })
    } catch (e) {
      setAlert({
        variant: 'danger',
        message: t('account.Logout failed'),
      })

      isLoggingOut.value = false
    }

    try {
      const notifications = await getNotifications()

      notifications.forEach((notification) => {
        notification.close()
      })
    } catch (e) {
      console.error(e)
    }

    isLoggingOut.value = false
  }
</script>

<template>
  <div class="account-section">
    <h1 class="title">
      {{ t('common.My user informations') }}
    </h1>
    <div
      v-if="me"
      class="fields-wrapper"
    >
      <div class="fields-group">
        <AlgTextInput
          id="account-first-name"
          class="field"
          variant="secondary"
          size="l"
          :label="t('common.First name')"
          :model-value="me?.data?.first_name ?? null"
          suffix-icon="lock"
          disabled
        />
        <AlgTextInput
          id="account-last-name"
          class="field"
          variant="secondary"
          size="l"
          :label="t('common.Last name')"
          :model-value="me?.data?.last_name ?? null"
          suffix-icon="lock"
          disabled
        />
      </div>
      <div class="fields-group">
        <AlgTextInput
          id="account-email"
          class="field"
          type="email"
          variant="secondary"
          size="l"
          :label="t('common.Email address')"
          :model-value="me?.data?.email ?? null"
          suffix-icon="lock"
          disabled
        />
        <AlgTextInput
          id="account-identifier"
          class="field"
          variant="secondary"
          size="l"
          :label="t('common.Identifier')"
          :model-value="me?.data?.identifier ?? null"
          suffix-icon="lock"
          disabled
        />
      </div>
    </div>
  </div>
  <!--
  <div class="account-section">
    <h1 class="title">
      {{ lang.trans('_.Settings') }}
    </h1>
    <AlgSelect
      id="language"
      class="language-select"
      :label="lang.trans('_.Language')"
      :options="languageOptions"
      :model-value="lang.getLocale()"
      @update:model-value="handleChangeLanguage"
    />
  </div>
  -->
  <div class="account-section">
    <AlgButton
      icon-start="logout"
      :label="t('account.Logout')"
      :loading="isLoggingOut"
      @click="handleLogout"
    />
  </div>
</template>

<style lang="scss" src="./index.scss" />

<style lang="scss" scoped>
  @media screen and (min-width: $w-medium-s) {
    .language-select {
      width: 50%;
    }
  }
</style>
