import AppLayout from '@/layouts/app/App.vue'
import { RouterView } from 'vue-router'

const documentationRouter = [
  {
    path: 'documentation',
    redirect: { name: 'documentation-information-notice' },
    name: 'documentation',
    meta: {
      layout: AppLayout,
      breadcrumb: 'Documentation',
    },
    component: RouterView,
    children: [
      {
        path: 'information-notice',
        name: 'documentation-information-notice',
        meta: {
          breadcrumb: 'Information notice',
        },
        component: () => import('@/sections/documentation/DocumentationPage.vue'),
      },
      {
        path: 'online-support',
        name: 'documentation-online-support',
        meta: {
          breadcrumb: 'Online support',
        },
        component: () => import('@/sections/documentation/DocumentationPage.vue'),
      },
      {
        path: 'gdpr',
        name: 'documentation-gdpr',
        meta: {
          breadcrumb: 'GDPR',
        },
        component: () => import('@/sections/documentation/DocumentationPage.vue'),
      },
    ],
  },
]

export { documentationRouter }
